import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const CountrySearch = ({ id, label, updateLocation, defaultLocationId, countries }) => {
  let defaultLocationName = '';
  if (id === 'location-select' && defaultLocationId !== '' && countries && countries.length > 2) {
    const index = countries.findIndex(item => item.id === defaultLocationId);
    defaultLocationName = countries[index];
  }
  return (
    <Autocomplete
      value={defaultLocationName}
      autoHighlight
      onChange={(event, newValue) => {
        updateLocation(newValue.id);
      }}
      onInputChange={(event, newValue) => {
        if (newValue === '') {
          updateLocation('');
        }
      }}
      options={countries}
      getOptionLabel={option => {
        // e.g value selected with enter, right from the input
        return option.name ? option.name : '';
      }}
      filterOptions={(options, params) => {
        let filtered = [];
        if (params.inputValue.length >= 2) {
          filtered = options.filter(option => {
            const countryName = option.name
              .toLowerCase()
              .startsWith(params.inputValue.toLowerCase());
            return countryName;
          });
        } else {
          filtered = countries;
        }
        return filtered;
      }}
      renderOption={option => option.name}
      freeSolo
      id={id}
      disableClearable
      style={{ maxWidth: '90%' }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='filled'
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
      noOptionsText='Cannot find country'
      variant='filled'
    />
  );
};
export default CountrySearch;
