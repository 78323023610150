import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';

export default function ActiveDrugs(props) {
  const styles = {
    mainDiv: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '2px',
      borderColor: '#A2A2A2',
      maxWidth: '90%',
    },
    chipContainer: { padding: '5px', minHeight: '40px'},
    chipSuccess: {
      margin: '5px',
      backgroundColor: `${
        typeof props.covermore === 'undefined'
          ? 'var(--doc-green)'
          : 'var(--cov-blue)'
      }`,
      color: 'var(--doc-white)',
    },
    chipFail: { margin: '5px', backgroundColor: 'var(gray)' },
    buttonSuccess: {
      backgroundColor: `${
        typeof props.covermore === 'undefined'
          ? 'var(--doc-green)'
          : 'var(--cov-blue)'
      }`,
      color: 'var(--doc-white)',
    },
  };

  return (
    <div style={{paddingTop: '20px'}}>
      <div style={styles.mainDiv}>
        <div
          className='bg-doc-green'
          style={{ textAlign: 'left', padding: '13px 15px', fontSize: '19px' }}
        >
          Identified Active Ingredients
        </div>
        <div style={styles.chipContainer}>
          {props.activeDrugsList.length > 0 &&
            props.activeDrugsList.map((ingredient, i) => {
              if (ingredient.match === false) {
                return (
                  <Tooltip
                    title='Unmatched Drug'
                    aria-label='unmatched drug warning'
                    key={i}
                  >
                    <Chip
                      label={ingredient.name}
                      style={styles.chipFail}
                      onDelete={() => {
                        props.deleteDrug(ingredient.name);
                      }}
                    />
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip
                    title='Matched Drug'
                    aria-label='successfully matched drug'
                    key={i}
                  >
                    <Chip
                      key={i}
                      label={ingredient.name}
                      style={styles.chipSuccess}
                      onDelete={() => {
                        props.deleteDrug(ingredient.name);
                      }}
                    />
                  </Tooltip>
                );
              }
            })}
        </div>
      </div>
      <div>
        <Chip key={'a1'} label='Matched Drug' style={styles.buttonSuccess}/>
        <Chip key={'a2'} label='Unmatched Drug' style={styles.chipFail} />
      </div>
    </div>
  );
}
