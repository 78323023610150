/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function DrugAutoComplete(props) {
  const [value, setValue] = useState('');

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        size='small'
        disabled={props.disabled}
        style={{ maxWidth: '90%' }}
        onChange={(event, newValue) => {
          props.updateDrugList(newValue);
          setValue(null);
        }}
        // eslint-disable-next-line no-unused-vars
        onBlur={e => setValue('')}
        filterOptions={(options, params) => {
          let filtered = [];
          let found = false;
          if (params.inputValue.length >= 2) {
            filtered = options.filter(option => {
              const drugName = option.name
                .toLowerCase()
                .startsWith(params.inputValue.toLowerCase());
              option.name.toLowerCase() === params.inputValue.toLowerCase()
                ? (found = true)
                : (found = false);
              return drugName;
            });
          }
          filtered.find(drug => {
            if (drug.name.toLowerCase() === params.inputValue.toLowerCase()) {
              found = true;
              return true;
            } else {
              return false;
            }
          });
          if (
            params.inputValue.length >= 2 &&
						params.inputValue !== '' &&
						found === false &&
						filtered.length <= 5
          ) {
            filtered.push({
              name: `Add "${params.inputValue}" as an unmatched drug`,
              value: params.inputValue,
              match: false,
            });
          }
          return filtered;
        }}
        id='find-active-ingredients'
        options={props.drugList}
        getOptionLabel={option => {
          // e.g value selected with enter, right from the input
          return option.name ? option.name : '';
        }}
        renderOption={option => option.name}
        freeSolo
        renderInput={params => (
          <TextField {...params} label='Enter active ingredients' variant='filled' />
        )}
        noOptionsText='Cannot match drug'
      />
    </React.Fragment>
  );
}
